<template>
    <v-card class="elevation-0" v-if="ready">
        <v-card-text class="px-0 secondary--text pt-2 pb-0">
            <template v-if="note.type == 1">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has provided the following medical information:</p>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Current Medications</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.medicationsDesc || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Medication Allergies</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.allergiesDesc || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Current Health Conditions</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.conditionsDesc || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Care Providers</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.providers || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3" v-if="content.otherDesc">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Other Medical Information</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.otherDesc || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 2">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has {{ (content.length > 1) ? content.length + ' dependents ' : 'a dependent' }} that need{{ (content.length > 1) ? '' : 's' }} care:</p>
                <template v-for="(item, idx) in content">
                    <v-card class="elevation-2 mb-2" :key="'pet' + idx">
                        <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">{{ item.name }} <span class="subtitle-1 pl-2 font-weight-normal">({{ item.age }})</span></v-card-title>
                        <v-divider />
                        <v-card-text class="secondary--text">
                            <p class="body-2 mt-0 mb-2">
                                <b>Temporary Care Provider:</b><br>
                                <span class="nl2br lh-12" v-html="item.tempCare || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2" v-if="item.dailyCare">
                                <b>Daily Care Instructions:</b><br>
                                <span class="nl2br lh-12" v-html="item.dailyCare || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2" v-if="item.school">
                                <b>School/Nursery or Daily Schedules:</b><br>
                                <span class="nl2br lh-12" v-html="item.school || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2" v-if="item.providers">
                                <b>Medical/Care Providers:</b><br>
                                <span class="nl2br lh-12" v-html="item.providers || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2">
                                <b>Current Medications:</b><br>
                                <span class="nl2br lh-12" v-html="item.medications || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2">
                                <b>Medical Conditions:</b><br>
                                <span class="nl2br lh-12" v-html="item.conditions || 'No information provided.'" />
                            </p>
                            <p :class="(item.additional) ? 'body-2 mb-2' : 'body-2 mb-0'">
                                <b>Allergies:</b><br>
                                <span class="nl2br lh-12" v-html="item.allergies || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-0" v-if="item.additional">
                                <b>Additional Care Information:</b><br>
                                <span class="nl2br lh-12" v-html="item.additional || 'No information provided.'" />
                            </p>
                        </v-card-text>
                    </v-card>
                </template>
            </template>
            <template v-if="note.type == 3">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has {{ (content.length > 1) ? content.length + ' pets ' : 'a pet' }} that need{{ (content.length > 1) ? '' : 's' }} care:</p>
                <template v-for="(item, idx) in content">
                    <v-card class="elevation-2 mb-2" :key="'pet' + idx">
                        <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">{{ item.name }} <span class="subtitle-1 pl-2 font-weight-normal">({{ item.typeOther || item.type }})</span></v-card-title>
                        <v-divider />
                        <v-card-text class="secondary--text">
                            <p class="body-2 mt-0 mb-2">
                                <b>Feeding Instructions:</b><br>
                                Feed {{ item.feedingQty }} {{ item.feedingUnit }}{{ (item.feedingQty > 1) ? 's' : '' }} &bull; {{ item.feedingFreq }}x daily.
                                <br>
                                Food type/brand: {{ item.feedingBrand }}
                                <br>
                                Food is located: {{ item.feedingLocation }}
                            </p>
                            <p class="body-2 mb-2" v-if="item.feedingExtra">
                                <b>Additional Feeding Notes:</b><br>
                                <span class="nl2br lh-12" v-html="item.feedingExtra || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2">
                                <b>Potty Routine:</b><br>
                                <span class="nl2br lh-12" v-html="item.potty || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2" v-if="item.accessories">
                                <b>Toys &amp; Accessories:</b><br>
                                <span class="nl2br lh-12" v-html="item.accessories || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-2" v-if="item.medical">
                                <b>Medical/Care Providers:</b><br>
                                <span class="nl2br lh-12" v-html="item.medical || 'No information provided.'" />
                            </p>
                            <p class="body-2 mb-0" v-if="item.careExtra">
                                <b>Additional Care Information:</b><br>
                                <span class="nl2br lh-12" v-html="item.careExtra || 'No information provided.'" />
                            </p>
                        </v-card-text>
                    </v-card>
                </template>
            </template>
            <template v-if="note.type == 4">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has provided the following information for accessing and caring for their property:</p>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Property Address</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text mb-0">
                            {{ content.street }} {{ content.street_2 }}<br>
                            {{ content.city }}, {{ content.state }} {{ content.postal }}<br>
                            <a :href="'https://www.google.com/maps/place/' + content.street + ' ' + content.city + ' ' + content.state + ' ' + content.postal" target="_blank">Get Directions</a>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Accessing the Property</v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text">
                        <p class="body-2 mb-0" v-if="!content.accessing && !content.security">No information provided.</p>
                        <p :class="(content.security) ? 'body-2 mb-2' : 'body-2 mb-0'" v-if="content.accessing">
                            <b>Access Instructions:</b><br>
                            <span class="nl2br lh-12" v-html="content.accessing || 'No information provided.'" />
                        </p>
                        <p class="body-2 mb-0" v-if="content.security">
                            <b>Entering the Property:</b><br>
                            <span class="nl2br lh-12" v-html="content.security || 'No information provided.'" />
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Caring for the Property</v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text">
                       <p class="body-2 mb-0" v-if="!content.inside && !content.outside && !content.securing">No information provided.</p>
                        <p :class="(content.outside || content.securing) ? 'body-2 mb-2' : 'body-2 mb-0'" v-if="content.inside">
                            <b>Caring for items inside the property:</b><br>
                            <span class="nl2br lh-12" v-html="content.inside || 'No information provided.'" />
                        </p>
                        <p :class="(content.securing) ? 'body-2 mb-2' : 'body-2 mb-0'" v-if="content.outside">
                            <b>Caring for items outside the property:</b><br>
                            <span class="nl2br lh-12" v-html="content.outside || 'No information provided.'" />
                        </p>
                        <p class="body-2 mb-0" v-if="content.securing">
                            <b>Securing the property:</b><br>
                            <span class="nl2br lh-12" v-html="content.securing || 'No information provided.'" />
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3" v-if="content.extra">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Additional Property Instructions</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.extra || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 5">
                <template v-for="note in content">
                    <v-card class="elevation-2 mb-3" :key="note.uuid">
                        <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">{{ note.title }}</v-card-title>
                        <v-card-text class="pt-2">
                            <p class="body-2 secondary--text nl2br lh-12 mb-0">{{ note.content }}</p>
                        </v-card-text>
                    </v-card>
                </template>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    
export default {
    name: 'AlertNote',
    props: {
        note: {
            required: true,
            type: Object,
        },
        name: {
            required: true,
            type: String
        }
    },
    components: {
    },
    computed: {
        content(){
            return JSON.parse(this.note.body);
        }
    },
    data: () => ({
        // content: '',
        ready: false
    }),
    methods: {
        initData(){
            // need to wait for expansion panel to create/open
            if(!this.note.type){
                this.note.type = 5;
            }
            setTimeout(() => {
                this.ready = true;
            }, 100);
            setTimeout(() => {
                this.handleLinks();
            }, 1500);
        },
        handleLinks(){
            let notes = document.querySelectorAll('.alert-note-card .v-card__text .nl2br:not(.linked)');
            notes.forEach(note => {
                note.innerHTML = this.linkify(note.innerHTML);
                note.classList.add('linked');
            });
        },
        onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        },
        linkify(input){

            if(input.includes('href')){
                return input;
            }

            let text = input;
            let links = [];
            let linksFound = text.match( /(?:www|https?)[^\s]+/g ) || [];
            let emailsFound = text.match( /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim ) || [];
            let phonesFound = text.match( /([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6})/gim ) || [];
            let aLink = [];
            links = [].concat(linksFound, emailsFound, phonesFound);

            if ( links.length ) {

                links = links.filter(this.onlyUnique);

                for ( let i=0; i<links.length; i++ ) {
                    let replace = links[i];
                    if(links[i].includes('http')){
                        if ( !( links[i].match( /(http(s?)):\/\// ) ) ) { replace = 'http://' + links[i] }
                        aLink.push( '<a href="' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }else if(links[i].includes('@')){
                        aLink.push( '<a href="mailto:' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }else{
                        aLink.push( '<a href="tel:' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }
                }
                return text;

            }
            else {
                return input;
            }
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>